import Poster from "../../../img/PosterSQ.jpg";
import AAAposter from "../../../img/AAA-PosterSQ.png"
import React, { } from "react";

function MainPage() {
    return (
        <React.Fragment>
            <div className="container main d-flex justify-content-center align-items-center">
                <div className="leftSideContainer align-items-center">
                    <p className="tictoc"> <span className="toc" /></p>
                    <h1 className="landing">Landing</h1>
                    <h1 className="landing">Soon.</h1>
                </div>
                <div className="rightSideContainer">
                    <p className="upnext"> UP NEXT: </p>
                    <div className="poster-container">
                        <section>
                            <img src={Poster} className="poster-image" alt="Pulp-Summerslam"></img>
                            <img src={AAAposter} className="poster-image1" alt="Asia-Artist-Awards"></img>
                        </section>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MainPage;