import React, { } from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import BrandImage from "../../../img/BrandImage.png";

function Header() {
    return (
        <React.Fragment>
            <Navbar className="navbar">
                    <Navbar.Brand><img src={BrandImage} alt="tictoctickets" className="brand-image pe-3" /></Navbar.Brand>
            </Navbar>
        </React.Fragment>
    );
}
export default Header;