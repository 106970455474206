import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Page1 from "./Layouts/Pages/Page1";
import './App.css';
import Header from './Layouts/Components/Header/Header';
import Footer from './Layouts/Components/Footer/Footer';

function App() {
  return (
    <Router>
      <div className='container-fluid bg-image-tictoctickets overflow-scroll'>
      <Header />
      <Routes>
        <Route path="/" element={<Page1 />} />
      </Routes>
      <Footer/>
      </div>
    </Router>
  );
}

export default App;
